import gsap from "gsap";

export default (baseElement, clickTarget, startDelay) => {
  if (!baseElement) return;

  const badgeSVG = baseElement.querySelector(".badge-svg");

  if (!badgeSVG) return;

  const svg = badgeSVG.querySelector("svg#js-badge");

  if (!svg) return;

  const badgeName = baseElement.querySelector(".badge-name") || null;

  const outerBorder = baseElement.querySelector("#js-outer-border");
  const ring1 = baseElement.querySelector("#js-ring-1");
  const ring2 = baseElement.querySelector("#js-ring-2");

  const ring3 = baseElement.querySelector("#js-ring-3");
  const ring3OuterBorder = ring3.querySelector("#js-ring-outer-border");
  const ring3InnerBorder = ring3.querySelector("#js-ring-inner-border");
  const ring3Inner = ring3.querySelector("#js-ring-inner");
  const ring3Background = ring3.querySelector("#js-ring-background");

  const flare = baseElement.querySelector("#js-flare");

  const ringFocus = baseElement.querySelector("#js-ring-focus");
  const mainIcon = ringFocus.querySelector("#js-main-icon");
  const leftStars = ringFocus.querySelector("#js-left-stars");
  const rightStars = ringFocus.querySelector("#js-right-stars");
  const divider = ringFocus.querySelector("#js-divider");
  const logo = ringFocus.querySelector("#js-logo");
  const title = ringFocus.querySelector("#js-title");
  const subtitle = ringFocus.querySelector("#js-subtitle");

  const init = () => {
    gsap.set(svg, {
      overflow: "visible",
      opacity: 1
    });
    gsap.set([outerBorder, ring1, ring2, ring3, flare], {
      transformOrigin: "center"
    });
    gsap.set(
      [ring3OuterBorder, ring3InnerBorder, ring3Background, ring3Inner],
      {
        transformOrigin: "center"
      }
    );
    gsap.set([ringFocus, ring3], {
      opacity: 1
    });
    gsap.set([outerBorder, ring1, ring2], {
      opacity: 0
    });
    gsap.set(
      [ring3OuterBorder, ring3InnerBorder, ring3Background, ring3Inner],
      {
        opacity: 0
      }
    );
    gsap.set([mainIcon, leftStars, rightStars], {
      opacity: 0
    });
    gsap.set([logo, divider, title, subtitle], {
      opacity: 0
    });
    badgeName &&
      gsap.set([badgeName], {
        opacity: 0,
        y: -20
      });
    gsap.set([flare], {
      opacity: 0
    });
  };

  init();

  const mainAnimation = gsap
    .timeline({ delay: startDelay })
    //Outer Border (red ribbon)
    .addLabel("outerBorderStart")
    .fromTo(
      outerBorder,
      { opacity: 0 },
      { opacity: 1, duration: 2, ease: "expo.out" }
    )
    .fromTo(
      outerBorder,
      { scale: 0 },
      { scale: 1, duration: 0.5, ease: "back.out(1.7)" },
      "outerBorderStart"
    )
    .fromTo(
      outerBorder,
      { rotate: -90 },
      { rotate: 0, duration: 1, ease: "elastic.out(1.5,0.5)"},
      "outerBorderStart"
    )
    //Ring 1 (Yellow Inner)
    .addLabel("ring1Start", "outerBorderStart+=0.5")
    .fromTo(ring1, { opacity: 0 }, { opacity: 1, duration: 1 }, "ring1Start")
    .fromTo(
      ring1,
      { scale: 0 },
      { duration: 1, scale: 1, ease: "elastic.out(0.8,0.5)" },
      "ring1Start-=0.2"
    )
    //Ring 2 (White Inner w/ Stars)
    .addLabel("ring2Start", "outerBorderStart-=0.1")
    .fromTo(
      ring2,
      { opacity: 0 },
      { opacity: 1, duration: 0.1, ease: "expo.out" },
      "ring2Start"
    )
    .fromTo(
      ring2,
      { scale: 0 },
      { scale: 1, duration: 0.5, ease: "expo.inOut" },
      "ring2Start"
    )
    .fromTo(
      ring2,
      { rotate: 0 },
      { rotate: 360, duration: 0.2, repeat: 0, ease: "none" },
      "ring2Start"
    )
    .fromTo(
      ring2,
      { rotate: 0 },
      { rotate: 360, duration: 3, ease: "expo.out" },
      ">"
    )
    //Ring 3 (Inner Circles)
    .addLabel("ring3Start", "outerBorderStart+=0.5")
    .fromTo(
      ring3Background,
      { opacity: 0 },
      { opacity: 1, duration: 0.5, ease: "expo.out" },
      "ring3Start"
    )
    .fromTo(
      ring3OuterBorder,
      { scale: 0, opacity: 0 },
      { scale: 1, opacity: 1, duration: 0.65, ease: "expo.inOut" },
      "ring3Start+=0.1"
    )
    .fromTo(
      ring3Inner,
      { scale: 0, opacity: 0 },
      { scale: 1, opacity: 1, duration: 0.65, ease: "expo.inOut" },
      "ring3Start+=0.3"
    )
    .fromTo(
      ring3Inner,
      { rotate: 0 },
      { rotate: -360, duration: 0.2, repeat: 1, ease: "none" },
      "ring3Start+=0.3"
    )
    .fromTo(
      ring3Inner,
      { rotate: 0 },
      { rotate: -360, duration: 3, ease: "expo.out" },
      ">"
    )
    .fromTo(
      ring3InnerBorder,
      { scale: 0, opacity: 0 },
      { scale: 1, opacity: 1, duration: 0.65, ease: "expo.inOut" },
      "ring3Start+=0.5"
    )
    //Main Icon
    .addLabel("mainIconStart", ">")
    .fromTo(
      mainIcon,
      { opacity: 0, y: 40 },
      { opacity: 1, y: 0, duration: 0.5, ease: "back.out(1.7)" },
      "mainIconStart"
    )
    .fromTo(
      leftStars,
      { opacity: 0, x: 20, y: 40 },
      { opacity: 1, x: 0, y: 0, duration: 0.5, ease: "back.out(1.7)" },
      "mainIconStart+=0.3"
    )
    .fromTo(
      rightStars,
      { opacity: 0, x: -20, y: 40 },
      { opacity: 1, x: 0, y: 0, duration: 0.5, ease: "back.out(1.7)" },
      "mainIconStart+=0.3"
    )
    //Texts
    .addLabel("titleStart", ">")
    .fromTo(logo, { opacity: 0 }, { opacity: 1, duration: 0.3 }, "titleStart")
    .fromTo(divider, { opacity: 0 }, { opacity: 1, duration: 0.3 }, ">+=0.1")
    .fromTo(title, { opacity: 0 }, { opacity: 1, duration: 0.3 }, ">")
    .fromTo(subtitle, { opacity: 0 }, { opacity: 1, duration: 0.3 }, ">");

  //Since badges on profile page doesn't have badge-name on this markup tree
  badgeName &&
    mainAnimation
      .to(badgeName, { opacity: 1, duration: 1 }, "<")
      .to(badgeName, { y: 0, duration: 0.6, ease: "back.out(2)" }, ">=-1");

  //Flare
  mainAnimation
    .addLabel("flareStart", ">-=2")
    .fromTo(flare, { opacity: 0 }, { opacity: 1, duration: 2 }, "flareStart")
    .fromTo(
      flare,
      { scale: 2 },
      { scale: 1, duration: 3, ease: "expo.out" },
      "flareStart"
    )
    .fromTo(
      flare,
      { rotate: 0 },
      { rotate: 360, duration: 5, ease: "expo.out" },
      "flareStart"
    );

  //Events
  if (window.matchMedia("(max-width:1024px)").matches) {
    /**
     * On Ipad and below, we make the badge clickable to animate, and transfer the link target to the badge name
     */

    //Transfer click event to badgeName
    if (badgeName) {
      badgeName.style.textDecoration = "underline";
      badgeName.addEventListener("click", () => {
        window.location.href = clickTarget.href;
      });
    }

    clickTarget.addEventListener("click", (e) => {
      e.preventDefault();
      gsap.fromTo(svg, { opacity: 1 }, { opacity: 0, duration: 0.3 });
      gsap.fromTo(
        svg,
        { scale: 1 },
        {
          scale: 0,
          duration: 0.3,
          ease: "back.in(2)",
          onComplete: () => {
            gsap.set(svg, {
              scale: 1,
              opacity: 1
            });
            mainAnimation.restart();
          }
        }
      );
    });
  } else {
    clickTarget.addEventListener("mouseenter", () => {
      gsap.fromTo(svg, { opacity: 1 }, { opacity: 0, duration: 0.3 });
      gsap.fromTo(
        svg,
        { scale: 1 },
        {
          scale: 0,
          duration: 0.3,
          ease: "back.in(2)",
          onComplete: () => {
            gsap.set(svg, {
              scale: 1,
              opacity: 1
            });
            mainAnimation.restart();
          }
        }
      );
    });
  }
};
