import setupBadgeAnimation from './setupBadgeAnimation';

export default () => {
  //Dashboard Badges
  document
    .querySelectorAll('#page-my-index ul.badges > li')
    .forEach((badgeListItem, i) => {
      if (badgeListItem.querySelector('a .badge-svg--earned')) {
        setupBadgeAnimation(
          badgeListItem.querySelector('a'),
          badgeListItem.querySelector('a'),
          2.5 + i * 0.2
        );
      }
    });

  //Profile Page Badges
  document
    .querySelectorAll('#page-user-profile ul.badges > li')
    .forEach((badgeListItem, i) => {
      if (badgeListItem.querySelector('a .badge-svg--earned')) {
        setupBadgeAnimation(
          badgeListItem.querySelector('a'),
          badgeListItem.querySelector('a'),
          0.5 + i * 0.2
        );
      }
    });

  //Badge Page
  setupBadgeAnimation(
    document.querySelector('#page-badges-badge'),
    document.querySelector('#page-badges-badge .badge-svg'),
    0.5
  );

  //Manage Badges Page
  document
    .querySelectorAll('#page-badges-mybadges ul.badges > li')
    .forEach((badgeListItem, i) => {
      if (badgeListItem.querySelector('a .badge-svg--earned')) {
        setupBadgeAnimation(
          badgeListItem.querySelector('a'),
          badgeListItem.querySelector('a'),
          0.5 + i * 0.2
        );
      }
    });
};
