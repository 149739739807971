export default async () => {
  //Wait for JQuery since it already exists with moodle
  const waitForJQuery = async () => {
    const WAIT_INTERVAL = 1000;
    const TIMEOUT = 20000;
    let counter = 0;

    while (!window.$) {
      counter += WAIT_INTERVAL;
      console.log(
        'Waiting for JQuery... elapsed',
        `${counter / WAIT_INTERVAL}s`
      );
      if (counter >= TIMEOUT) break;
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  };
  await waitForJQuery();

  //First trap for add a new user page
  const addNewUserPage = document.querySelector(
    'body#page-admin-user-editadvanced'
  );
  if (!addNewUserPage) return;

  //Make sure form exists
  const addNewUserForm = addNewUserPage.querySelector(
    'form[action*="editadvanced.php"]'
  );
  if (!addNewUserForm) return;

  //Bootstrap Popup
  const popup = $('#cohort-reminder-popup');

  popup.on('hidden.bs.modal', () => {
    addNewUserForm.submit();
  });
  addNewUserForm.addEventListener('submit', (e) => {
    e.preventDefault();

    //Use moodle's existing validation flags
    const hasErrors = addNewUserForm.querySelectorAll('.has-danger');
    const hasBeenSubmitted = !new URLSearchParams(window.location.search).get(
      'id'
    );

    if (hasErrors.length === 0 || hasBeenSubmitted) {
      popup.modal({
        backdrop: 'static'
      });
    }
  });
};
