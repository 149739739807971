
import LightBoxSetup from './modules/lightbox-setup';
import BadgeAnimation from './modules/badge-animation/badge-animation';
//import CourseIndexCollapse from './modules/courseindex-collapse';
import CohortReminder from './modules/cohort-reminder';

document.addEventListener('DOMContentLoaded', () => {
  BadgeAnimation();
  LightBoxSetup();
  //CourseIndexCollapse();
  CohortReminder();
});
