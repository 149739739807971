import SimpleLightbox from 'simplelightbox';

export default () => {
  //Set lightbox trigger
  new SimpleLightbox('.js-lightbox img', {
    sourceAttr: 'src'
  });

  //Add Lightbox button triggers for multi-choice with images
  document.querySelectorAll('.answer').forEach((answerBlock) => {
    if (answerBlock.querySelector('img')) {
      answerBlock.querySelectorAll(':scope > div').forEach((choiceBlock) => {
        //Create and insert the lightbox button
        const lightboxButton = document.createElement('a');
        lightboxButton.className =
          'js-lightbox-button lightbox-button btn btn-dark';
        lightboxButton.innerHTML = '<i class="fa-solid fa-expand"></i>';
        lightboxButton.href = choiceBlock.querySelector('img').src;
        choiceBlock.insertAdjacentElement('afterBegin', lightboxButton);

        //Set lightbox trigger
        new SimpleLightbox(lightboxButton, {
          sourceAttr: 'href'
        });
      });
    }
  });
};
